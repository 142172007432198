<template>
  <b-row class="match-height">
    <b-col lg="12">
      <b-card title="Receive Purchase Order By Warehouse">
        <b-form @keyup.enter.prevent="" @submit.prevent="submitReceivePurchaseOrder">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Items *" label-for="chosenItem">
                <v-select-new
                  v-model="chosenItem"
                  :filterable="true"
                  :options="items"
                  :get-option-label="getOptionLabel"
                  :get-option-key="getOptionKey"
                  @input="putItem"
                  @search="inputSearch"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col cols="4" class="text-center font-weight-bold">
                  Item name
                </b-col>
                <b-col cols="1" class="text-center font-weight-bold">
                  Unit Item
                </b-col>
                <b-col cols="1" class="text-center font-weight-bold">
                  QTY Ordered
                </b-col>
                <b-col cols="1" class="text-center font-weight-bold">
                  QTY Received
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Shelves
                </b-col>
              <b-col
cols="2"
class="text-center font-weight-bold"
>
                Is Condition Checked
              </b-col>
                <b-col cols="1" class="text-center font-weight-bold"
                  >Action</b-col
                >
              </b-row>
              <p />
              <b-row v-for="chosen in chosens" :key="chosen.value">
                <br />
                <b-col cols="4">
                  <b-list-group>
                    <b-list-group-item href="#">
                      {{ chosen.label }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col cols="1" class="text-center">
                  <b-form-input v-model="chosen.unit" readonly />
                </b-col>
                <b-col cols="1" class="text-center">
                  <b-form-input
                    v-model="chosen.qty"
                    :max="chosen.qty"
                    readonly
                  />
                </b-col>
                <b-col cols="1" class="text-center">
                  <b-form-input
                    v-model="chosen.received"
                    type="number"
                    :max="chosen.max"
                  />
                </b-col>
                <b-col cols="2" class="text-center">
                  <v-select-new v-model="chosen.shelf" :options="chosen.shelves" />
                </b-col>
              <b-col cols="2" class=" text-center float-center">
                <b-form-checkbox
                  v-model="chosen.checked"
                  class="mb-1 float-center"
                />
              </b-col>
                <b-col cols="1" class="text-center">
                  <b-button
                    size="sm"
                    type="button"
                    variant="danger"
                    class="mr-1"
                    @click="removeRow(chosen.id)"
                  >
                    <feather-icon icon="TrashIcon" size="18" />
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <br />
                </b-col>
              </b-row>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="12">
              <b-button type="submit" variant="primary" class="mr-1"
              :disabled="submitButtonDisabled">
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
  BFormDatepicker,
  BFormFile,
  BFormCheckbox,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Ripple from "vue-ripple-directive"
import { ref } from "@vue/composition-api"

const VersionCheck = ""
const file = ""
const items = []
const chosenPR = ref()
const chosens = []
const shipmentTo = ""
const itemId = ""
const purchaseOrderProject = ""
const purchaseOrderCostCenter = ""
const purchaseOrderQtyRequested = 0
const purchaseOrderStatus = ""
const purchaseOrderNotes = ""
const deliverySchedule = ""
const modalReferenceShow = false
const itemReference = ""
const itemLink = ""
const itemPrice = ""
const itemDescription = ""
const contacts = []
const branches = []
const references = []
const currencies = []
const returns = []
const chosenBranch = ""
const chosenContact = ""
const termOfPayment = ""
const billingAddress = ""
const deliveryAddress = ""
const purchaseOrderDetailId = ""
const modalReturnShow = false
const itemCurrency = ""
const returnItemQty = ""
const chosenShelf = ""
const chosenItem = ""
const shelfs = []
const modalReferenceHistoryShow = false
const submitButtonDisabled = false

export default {
  components: {
    BFormFile,
    BFormCheckbox,
    BFormDatepicker,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      submitButtonDisabled,
      chosenItem,
      shelfs,
      chosenShelf,
      returns,
      returnItemQty,
      purchaseOrderDetailId,
      modalReturnShow,
      itemCurrency,
      currencies,
      deliveryAddress,
      chosenBranch,
      branches,
      termOfPayment,
      billingAddress,
      modalReferenceHistoryShow,
      chosenContact,
      contacts,
      itemId,
      deliverySchedule,
      file,
      modalReferenceShow,
      itemReference,
      itemLink,
      itemPrice,
      itemDescription,
      references,
      VersionCheck,
      items,
      chosenPR,
      chosens,
      shipmentTo,
      purchaseOrderProject,
      purchaseOrderCostCenter,
      purchaseOrderQtyRequested,
      purchaseOrderStatus,
      purchaseOrderNotes,
    }
  },
  mounted() {
    const shelfsVariables = process.env.VUE_APP_SHELF_LIST.split(",")
    const shelfsRepeat = process.env.VUE_APP_SHELF_REPEAT.split(",")
    this.shelfs = []
    shelfsVariables.map(s => {
      shelfsRepeat.map(r => {
        this.shelfs.push(`${s}${r}`)
      })
    })
    this.getDetails()
    this.chosens = []
  },
  created() {
    window.setInterval(() => {
      this.removeReadOnly()
    }, 1000)
  },
  methods: {
    removeReadOnly() {
      const searches = document.getElementsByClassName("vs__search")
      for (let i = 0; i < searches.length; i += 1) {
        searches[i].removeAttribute("readonly")
      }
    },
    getDetails() {
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
      const item = JSON.parse(localStorage.getItem("PurchaseOrderDetail"))
      const itemReceive = JSON.parse(localStorage.getItem("PurchaseOrderReceive"))
      const obj1 = {
        title: "Created",
        content: `Purchase Order Has been Created at ${this.dateSimple(
          item.purchase_order_created_time,
        )}`,
        stepCssClass: "has-step-green",
        boxCssClass: "has-color-red",
      }
      const obj2 = {
        title: "Lab Manager",
        content:
          item.purchase_order_is_accepted_by_supervisor === true
            ? `Purchase Order Has been Accepted by Lab Manager at ${this.dateSimple(
                item.purchase_order_created_time,
              )}`
            : `Purchase Order Has not been Accepted By Lab Manager`,
        stepCssClass:
          item.purchase_order_is_accepted_by_supervisor === true
            ? "has-step-green"
            : "has-step-red",
      }
      const obj3 = {
        title: "Received",
        content:
          item.purchase_order_is_received === true
            ? `Purchase Order Has been Received at ${this.dateSimple(
                item.purchase_order_created_time,
              )}`
            : `Purchase Order Has not been Received`,
        stepCssClass:
          item.purchase_order_is_received === true
            ? "has-step-green"
            : "has-step-red",
      }
      this.statusItems = []
      this.statusItems.push(obj1)
      this.statusItems.push(obj2)
      this.statusItems.push(obj3)
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER_RECEIVE_WAREHOUSE_CHECK}${itemReceive.purchase_order_purchasing_receive_id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const temp = []
            response.data.data.map(elem => {
              const tempNum = parseInt(elem.purchase_order_purchasing_receive_detail_item_received) - parseInt(elem.receivedAtWarehouse)
              if (parseInt(elem.receivedAtWarehouse) < parseInt(elem.purchase_order_purchasing_receive_detail_item_received)) {
                const received = parseInt(elem.purchase_order_purchasing_receive_detail_item_received) - parseInt(elem.receivedAtWarehouse)
                temp.push({
                  itemId: elem.item.item_id,
                  label: `${elem.item.item_name} (${elem.item.item_code}) (QTY : ${elem.purchase_order_purchasing_receive_detail_item_requested})`,
                  unit: elem.item.unit ? elem.item.unit.unit_name : '-',
                  name: elem.item.item_name,
                  value: elem.purchase_order_purchasing_receive_detail_id,
                  qty:
                    elem.purchase_order_purchasing_receive_detail_item_requested,
                  received,
                  max:
                  tempNum,
                  shelf: "",
                  checked: false,
                  shelves: elem.shelves,
                  id: temp.length + 1,
                })
              }
            })
            this.items = temp
            // this.chosens = this.items.map((elem, id) => {
            //   return {
            //     itemId: elem.itemId,
            //     label: elem.name,
            //     value: elem.value,
            //     qty: elem.qty,
            //     received: elem.received,
            //     id,
            //   }
            // })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Data success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Data failed",
                icon: "CheckIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    submitReceivePurchaseOrder() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
      if (this.chosenItem.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Item cannot be empty",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
        return
      }
      const item = JSON.parse(localStorage.getItem("PurchaseOrderDetail"))
      const itemReceive = JSON.parse(
        localStorage.getItem("PurchaseOrderReceive"),
      )
      let totalReceived = 0
      let shelfFilled = true
      let emptyQty = false
      this.chosens.map(o => {
      o.shelf = o.shelf.value
        totalReceived += parseInt(o.received, 10)
        if (o.received === 0) {
          emptyQty = true
        }
        if (o.shelf === "") {
          shelfFilled = false
        }
      })
      if (shelfFilled === true) {
        if (totalReceived > 0) {
          if (emptyQty === false) {
      const id = item.purchase_order_id
      const body = {
        purchase_order_items: JSON.stringify(this.chosens),
        purchase_order_purchasing_receive_id:
          itemReceive.purchase_order_purchasing_receive_id,
        purchase_order_warehouse_receive_total_received: totalReceived,
        time: moment(),
      }
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_RECEIVE_WAREHOUSE_PURCHASE_ORDER}${id}`
      axios
        .post(url, body, { headers })
        .then(response => {
          if (response.data.success === true) {
            this.submitButtonDisabled = false
            this.chosens = []
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Receive Purchase Order Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
            this.$router.replace({
              name: "apps-purchase-order-pre-receive-warehouse",
            })
          } else {
            this.submitButtonDisabled = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Receive Purchase Order Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.submitButtonDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Receive Purchase Order Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
      } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Please put the qty received!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
      }
      } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Please put the qty received!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
      }
      } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Please fill all shelves",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
      }
    },
    getOptionLabel(option) {
      return (option && option.label) || ""
    },
    inputSearch(search) {
      console.log(search)
    },
    handleFilesUpload(e) {
      this.file = e.target.files || e.dataTransfer.files
    },
    addReference(val) {
      this.modalReferenceShow = true
      this.itemId = val
    },
    showReference(val) {
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
      this.itemId = val
      this.modalReferenceHistoryShow = true
      axios
        .get(`${process.env.VUE_APP_API_GET_ITEM_LOOKUP}${val}`, { headers })
        .then(response => {
          if (response.data.success === true) {
            this.references = response.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get References Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get References Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    dateSimple(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    returnItem(id) {
      this.purchaseOrderDetailId = id
      this.modalReturnShow = true
    },
    saveReturnItem() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Return Item Saved",
          icon: "CheckIcon",
          variant: "success",
        },
      })
    },
    shelfChange(id) {
      this.chosens.map(o => {
        if (o.value === id) {
          o.shelf = this.chosenShelf
        }
      })
    },
    getOptionKey(option) {
      return (option && option.value) || ""
    },
    putItem(val) {
      if (this.chosens.length > 0) {
        let item = null
        this.items.map(elem => {
          if (elem.value === val.value) {
            item = {
              itemId: elem.itemId,
              label: elem.name,
              unit: elem.unit,
              value: elem.value,
              qty: elem.qty,
              received: elem.received,
              checked: elem.checked,
              max: elem.max,
              shelves: elem.shelves,
              id: this.chosens.length + 1,
            }
          }
        })
        this.chosens.push(item)
      } else {
        let item = null
        this.items.map(elem => {
          if (elem.value === val.value) {
            item = {
              itemId: elem.itemId,
              label: elem.name,
              unit: elem.unit,
              value: elem.value,
              qty: elem.qty,
              max: elem.max,
              received: elem.received,
              checked: elem.checked,
              shelves: elem.shelves,
              id: this.chosens.length,
            }
          }
        })

        this.chosens.push(item)
      }
    },
    removeRow(val) {
      this.chosens = this.chosens.filter(obj => obj.id !== val)
    },
  },
}
</script>
